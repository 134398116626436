import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Headset from '../components/Headset'
import MailChimpForm from '../components/MailChimpForm'


export const IndexPageTemplate = ({
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  posts,
}) => (
  <div>
    
    <div
      className="full-width-image margin-top-0 videoBg"
      style={{height:'85vh'}}
    >

      <video autoplay='true' muted="true" loop="true" playsinline="true" className="video1">
    <source src="/img/audioswitch-home.webm" type="video/webm"/>  
    <source src="/img/audioswitch-home.mp4" type="video/mp4"/>
</video>
      <div className="videoMessage">
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered"
          style={{
            boxShadow:
              'rgb(245, 126, 77) 0.5rem 0px 0px, rgb(245, 126, 77) -0.5rem 0px 0px',
            backgroundColor: 'rgb(245, 126, 77)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {title}
        </h1>
        <h4
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-5-widescreen has-text-centered"
          style={{
            boxShadow:
              'rgb(245, 126, 77) 0.5rem 0px 0px, rgb(245, 126, 77) -0.5rem 0px 0px',
            backgroundColor: 'rgb(245, 126, 77)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {subheading}
          <div id="mailchimp-homepage" className="homepage-form" style={{marginTop: '20px',}}>
          <MailChimpForm />
          </div>    
    </h4>

      </div>
    
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content" style={{marginTop: "-3.1vw"}}>
                  <div className="tile">
                    <h3 className="has-text-weight-semibold is-size-2" >{mainpitch.title} <span >
              <img className='arrow-down' alt="arrow" style={{display: 'inline', height: '32px'}}/>
            </span></h3>
                  </div>
                  <div className="tile">
                    <h4 className="subtitle">{mainpitch.description}</h4>
                  </div>
                  
                </div>
                <div style={{overflow: 'hidden'}}>
                <Headset />
                </div>
                <div className="columns">
                  <div className="column is-12" >
                    <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                      {heading}
                    </h2>
                    <p style={{marginBottom: "10px"}}>{description}</p>
                  </div>
                </div>
                <div style={{overflow: 'hidden'}}>
                
                </div>
                
                <div className="columns"  style={{marginBottom: "20px"}}>
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/products/audioswitch/">
                      Learn more
                    </Link>
                  </div>
                </div>


                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data: { index, posts } }) => {
  const { frontmatter } = index

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        posts={posts.edges}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    index: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 2
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            tags
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`
