import React, { useRef, useState } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import headset from '../img/headset/headset.png'
import stand from '../img/headset/stand.png'
import stand2 from '../img/headset/stand2.png'

const Headset = () => {
  const startPos = -780
  const endPos = -8
  const offset = 200

  const headsetContainer = useRef()
  const [headsetPosition, setHeadsetPosition] = useState(startPos)

  useScrollPosition(({ prevPos, currPos }) => {
    const currPosAbsY = Math.abs(currPos.y)
    const prevPosAbsY = Math.abs(prevPos.y)

    if (currPosAbsY < headsetContainer.current.offsetTop + 385) {
      if (prevPosAbsY + 600 > currPosAbsY && headsetPosition > startPos) {
        setHeadsetPosition(headsetPosition - offset)
      } else {
        setHeadsetPosition(startPos)
      }
    } else if (currPosAbsY > headsetContainer.current.offsetTop) {
      if (prevPosAbsY +600 < currPosAbsY && headsetPosition < endPos) {
        setHeadsetPosition(headsetPosition + offset)
      } else {
        setHeadsetPosition(endPos)
      }
    }
  })

  return (
    <section ref={headsetContainer} className={'headset-container'}>
      <img id="headset" alt="Headset" src={headset} style={{ top: `${headsetPosition}px`, zIndex: "2" }}
      
      />
      <img className="stand" id="stand" src={stand} alt="Stand"
      style={{zIndex: "1"}}
      />
      <img className="stand" id="stand2" src={stand2} alt="Stand2"
      style={{zIndex: "3"}}
      />
    </section>
  )
}

export default Headset
